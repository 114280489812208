<template>
    <div class="box">
        <h1 id="about-title">michael hannawi</h1>
        <!-- <div class="clock">{{ dateTime.hours }}:{{ dateTime.minutes }}:{{ dateTime.seconds }}</div> -->
    </div>
</template>

<script>
    export default {
        name: "App",
        data() {
            const now = new Date();
            const pstOffset = -8;
            const utc = now.getTime() + (now.getTimezoneOffset() * 60000);
            const pstTime = new Date(utc + (3600000 * pstOffset));
            
            return {
            dateTime: {
                hours: pstTime.getHours().toString().padStart(2, '0'),
                minutes: pstTime.getMinutes().toString().padStart(2, '0'),
                seconds: pstTime.getSeconds().toString().padStart(2, '0'),
            },
            timer: null,
            };
        },
        methods: {
            setDateTime() {
                const date = new Date();
                const pstOffset = -8;
                const utc = date.getTime() + (date.getTimezoneOffset() * 60000);
                const pstTime = new Date(utc + (3600000 * pstOffset));

                this.dateTime = {
                    hours: pstTime.getHours().toString().padStart(2,'0'),
                    minutes: pstTime.getMinutes().toString().padStart(2,'0'),
                    seconds: pstTime.getSeconds().toString().padStart(2,'0'),
                };
            },
        },
        beforeMount() {
            this.timer = setInterval(this.setDateTime, 1000);
        },
        beforeUnmount() {
            clearInterval(this.timer);
        },
    };
</script>

<style scoped>
    .box{
        width: 10vw;
        height: 5%;
        /* border: 1px solid #2f3f4d;
        background-color: #1a1e26;
        border-radius: 4px;
        margin: 10px; */
        color: #0000ff;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
    }
    #about-title {
        font-size: 20px;
        font-weight: bold;
        white-space: nowrap;
    }
    /* text {
        font-size: 15px;
        margin-left: 15px;
        margin-right: 15px;
    } */
</style>