<template>
    <link href='https://unpkg.com/css.gg@2.0.0/icons/css/menu-boxed.css' rel='stylesheet'>
    <div class="box">
        <div id="box-content">
            <a>KEYWORDS</a>
            <ul>
                <li>sanctuary (nebiyu)</li>
                <li>resurgence of christianity</li>
                <li>consciousness</li>
                <li>instrument</li>
                <li>ultra evolved/civilized</li>
                <li>drowned/allowable for attachments</li>
                <li>minimalist in necessity</li>
            </ul>
            <a>QUOTE INSPIRATION</a>
            <ul>
                <li>"you are merely an instrument in the hands of the forces, participating in the harmony of balance."</li>
            </ul>
            <a>THESIS</a>
            <ul>
                <li>a hard instrument that allows for being a lone human on the inside that drowns his thoughts away, through echoes and dampening, with a transformative expansion to outside community club event. must remain acoustic, and not become an embedded hardware solution to the modern problem. must allow for attachments, space to grow and reduce and remain in pure age construction.</li>
            </ul>
            <a>SUBTHESIS</a>
            <ul>
                <li>use of columns within the building create the verticality of the architecture as the frets of the instrument. express their essence and use walls only when you have to, deliberately, for division, or form. this will add to the complete construction of the instrument remaining an architectural building rather than just a "meaning".</li>
                <li>the want to be inside and out at the same time while wanting to see the inside and out and the same time. how can we make that possible</li>
            </ul>
            <a>[form]</a>
            <ul>
                <li>hard to look at because there is something largely obstructing and taking control of your vision while there is something orderly beautiful that you are trying to make sense of</li>
                <li>windows media player skins</li>
                <li>open, center-oriented attention seeking kitchen and lounge area/social area contrasted to a very boldly divided contrasting muted room where service and presentation are considered. transition something of a wes anderson.</li>
            </ul>
            <a>FEATURES</a>
            <ul>
                <li>skateable facility</li>
                <li>stage for edm and studio connected/behind</li>
                <li>where are you sleeping the hardest (potential tower)</li>
                <li>creative italian kitchen with mini garden feature? (explore)</li>
                <li>deep underground foundation with car lot</li>
                <li>second floor outdoor lounge spot</li>
                <li>reserved area for prayer and meditation</li>
                <li>3rd floor section (concrete slab with walkway of wire net as wall and allow for possible vegetation)</li>
                <li>oversized garage door that captures a view similar to chicken point cabin by olsen kundig</li>
                <li>wonderful tile throughout</li>
                <li>futsal court</li>
                <li>warehouse of some sort for construction shop ?</li>
                <li>on a large plot of land ???</li>
                <li>house must allow for attachments, drowned look</li>
                <li>house must be free for expression from its inhabitants</li>
                <li>reflective water ??</li>
                <li id="solar-link"><a href="https://www.instagram.com/michaelhannawi/" target="_blank">solar</a></li>
                <li>sci fi</li>
                <li>hard inaccessible backroom</li>
                <li>must fit ai berlin</li>
                <li>must have 1 key recognized overarching form</li>
                <li>fresh pads</li>
            </ul>
            <a>MATERIALS, COLORS</a>
            <ul>
                <li>concrete, wire net</li>
                <li>ruby red</li>
                <li>glass cubes</li>
                <li>deep blue</li>
                <li>rich nature and earth</li>
                <li>yellow flowers</li>
            </ul>
            <a>THINGS TO CONSIDER</a>
            <ul>
                <li>passive cooling, circulation</li>
                <li>insulation</li>
                <li>natural light, (all lights off in house?)</li>
            </ul>

            <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Habitant morbi tristique senectus et netus et malesuada fames. Consequat ac felis donec et odio pellentesque diam volutpat commodo. Ac odio tempor orci dapibus ultrices in iaculis. Dolor sit amet consectetur adipiscing elit duis. Viverra justo nec ultrices dui sapien eget. Nisl suscipit adipiscing bibendum est ultricies integer quis auctor. Sed viverra tellus in hac habitasse platea dictumst vestibulum rhoncus. Ornare aenean euismod elementum nisi quis eleifend quam adipiscing. Sollicitudin nibh sit amet commodo. Mi bibendum neque egestas congue quisque egestas. Suspendisse faucibus interdum posuere lorem ipsum dolor sit amet consectetur. Feugiat nisl pretium fusce id velit. A diam sollicitudin tempor id eu. Neque ornare aenean euismod elementum nisi quis eleifend quam. Sit amet mattis vulputate enim. In ornare quam viverra orci sagittis eu volutpat odio facilisis.

            Turpis massa sed elementum tempus. Blandit massa enim nec dui nunc mattis enim. Lectus magna fringilla urna porttitor rhoncus dolor purus. Et leo duis ut diam. Ut consequat semper viverra nam. Vitae tortor condimentum lacinia quis vel eros donec. Suspendisse interdum consectetur libero id faucibus nisl. Tempus egestas sed sed risus pretium quam vulputate dignissim. Ac odio tempor orci dapibus. Blandit aliquam etiam erat velit scelerisque in dictum. Lacus vel facilisis volutpat est velit egestas dui id. Consectetur libero id faucibus nisl tincidunt eget nullam non nisi. Aliquam etiam erat velit scelerisque in dictum.

            Egestas congue quisque egestas diam in arcu cursus euismod. Mi ipsum faucibus vitae aliquet nec ullamcorper sit. Facilisi morbi tempus iaculis urna id volutpat lacus laoreet. Ultrices tincidunt arcu non sodales neque sodales ut etiam sit. Euismod quis viverra nibh cras pulvinar mattis nunc sed. Sapien eget mi proin sed libero enim. Bibendum arcu vitae elementum curabitur vitae nunc sed velit dignissim. Ridiculus mus mauris vitae ultricies. Sed nisi lacus sed viverra tellus in hac. Quam elementum pulvinar etiam non quam lacus suspendisse. Morbi tincidunt augue interdum velit euismod. Molestie at elementum eu facilisis sed. Morbi tincidunt ornare massa eget egestas purus. Quis risus sed vulputate odio ut enim blandit. Sit amet volutpat consequat mauris nunc congue nisi. Quis viverra nibh cras pulvinar mattis nunc. Ultrices tincidunt arcu non sodales neque sodales ut etiam.

            Ac placerat vestibulum lectus mauris ultrices eros in. Nibh ipsum consequat nisl vel pretium lectus quam id leo. Curabitur vitae nunc sed velit dignissim sodales. Id aliquet risus feugiat in ante metus dictum at. Egestas purus viverra accumsan in nisl nisi scelerisque. Sed egestas egestas fringilla phasellus faucibus. Ac tortor vitae purus faucibus ornare suspendisse sed nisi lacus. Ut tristique et egestas quis ipsum suspendisse ultrices gravida. Non odio euismod lacinia at quis risus sed. Et netus et malesuada fames ac turpis egestas maecenas pharetra. Donec ultrices tincidunt arcu non sodales neque sodales. Ut tortor pretium viverra suspendisse potenti nullam ac tortor vitae. Magna eget est lorem ipsum dolor sit amet consectetur. -->
        </div>
    </div>
</template>

<style scoped>
    p {
        font-weight: bold;
    }
    #box-content {
        color: #0000ff;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        overflow-y: auto;
    }

    .box {
        width: 34em;
        height: 30em;
        overflow: hidden;
    }

    #box-content {
        height: 100%;
        overflow-y: auto;
        color: #0000ff;
    }
    /* Custom scrollbar styles for webkit browsers */
    ::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
    }
    ::-webkit-scrollbar-track {
    background: #f0f0f0; /* Background of the scrollbar track */
    border-radius: 6px;
    }
    ::-webkit-scrollbar-thumb {
    background: #54B0FF; /* Color of the scrollbar thumb */
    border-radius: 6px;
    }
    /* Custom scrollbar styles for Firefox */
    * {
    scrollbar-width: thin;
    scrollbar-color: black #ffe0b3;
    }
        ::-webkit-scrollbar-track {
        background: #f1f1f1; /* Color of the scrollbar track */
    }       
    @media (max-width: 480px) {
        .box {
            width: 100vmin;
            height: 100vmin;
            padding: 5px;
            border: none; /* Remove border on very small screens for a cleaner look */
        }

        #box-content {
            font-size: 12px; /* Further adjust font size for very small screens */
        }
    }
    ul {
         list-style-type: '- '; 
    }
    a {
        color: inherit;
        text-decoration: none;
    }
    #solar-link a:hover {
        color: limegreen;
        cursor: url("../../mikefavicon-cursor.png") 16 16, auto;
    }

</style>
